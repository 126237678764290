import React from 'react';
import './stylesEtiquetasMasc.css';
import Eti201 from '../../imagens/etiquetas/eti201.png';
import Eti202 from '../../imagens/etiquetas/eti202.png';
import Eti203 from '../../imagens/etiquetas/eti203.png';
import Eti204 from '../../imagens/etiquetas/eti204.png';
import Eti205 from '../../imagens/etiquetas/eti205.png';
import Eti211 from '../../imagens/etiquetas/eti211.png';
import Eti221 from '../../imagens/etiquetas/eti221.png';
import Eti231 from '../../imagens/etiquetas/eti231.png';
import Eti298 from '../../imagens/etiquetas/eti298.png';
import Eti299 from '../../imagens/etiquetas/eti299.png';
import Eti301 from '../../imagens/etiquetas/eti301.png';
import Eti302 from '../../imagens/etiquetas/eti302.png';
import Eti303 from '../../imagens/etiquetas/eti303.png';
import Eti304 from '../../imagens/etiquetas/eti304.png';
import Eti305 from '../../imagens/etiquetas/eti305.png';
import Eti901 from '../../imagens/etiquetas/eti901.png';
import Eti902 from '../../imagens/etiquetas/eti902.png';
import Eti904 from '../../imagens/etiquetas/eti904.png';

const EtiquetasMasc = () => {
    const sections = [
        {
            titulo: '2 Colunas - Tamanho 50x25mm',
            etiquetas: [
                {
                    modelo: 'Modelo 221',
                    image: Eti221,
                    texts: [                        
                        'E221 - Linguagem EPL e PPLB',
                        'Z221 - Linguagem ZPL',
                    ],
                },            
                
                {
                    modelo: 'Modelo 231',
                    image: Eti231,
                    texts: [                        
                        'E231 - Linguagem EPL e PPLB',
                        'Z231 - Linguagem ZPL',
                    ],
                },            
            ],
        },

        {
            titulo: '2 Colunas - Tamanho 50x27mm',
            etiquetas: [
                {
                    modelo: 'Modelo 201',
                    image: Eti201,
                    texts: [
                        'A201 - Linguagem PPLA | Argox',
                        'E201 - Linguagem EPL e PPLB',
                        'Z201 - Linguagem ZPL',
                    ],
                },
                {
                    modelo: 'Modelo 202',
                    image: Eti202,
                    texts: [
                        'A202 - Linguagem PPLA | Argox',
                    ],
                },

                {
                    modelo: 'Modelo 203',
                    image: Eti203,
                    texts: [
                        'E203 - Linguagem EPL e PPLB',
                    ],
                },

                {
                    modelo: 'Modelo 204',
                    image: Eti204,
                    texts: [
                        'E204 - Linguagem EPL e PPLB',
                        'Z204 - Linguagem ZPL',
                    ],
                },

                {
                    modelo: 'Modelo 205',
                    image: Eti205,
                    texts: [
                        'E205 - Linguagem EPL e PPLB',
                        'Z205 - Linguagem ZPL',
                    ],
                },

                {
                    modelo: 'Modelo 904',
                    image: Eti904,
                    texts: [
                        'E904 - Linguagem EPL e PPLB',
                    ],
                },
            ],
        },
        {
            titulo: '2 Colunas - Tamanho 50x30mm',
            etiquetas: [
                {
                    modelo: 'Modelo 211',
                    image: Eti211,
                    texts: [
                        'E211 - Linguagem EPL e PPLB',
                    ],
                },
            ],
        },

        {
            titulo: '2 Colunas - Tamanho 36x54mm',
            etiquetas: [
                {
                    modelo: 'Modelo 298',
                    image: Eti298,
                    texts: [
                        'E298 - Linguagem EPL e PPLB',
                    ],
                },{
                    modelo: 'Modelo 299',
                    image: Eti299,
                    texts: [
                        'E299 - Linguagem EPL e PPLB',
                    ],
                },
            ],
        },

        {
            titulo: '3 Colunas - Tamanho 33x21mm',
            etiquetas: [
                {
                    modelo: 'Modelo 301',
                    image: Eti301,
                    texts: [
                        'A301 - Linguagem PPLA | Argox',
                        'E301 - Linguagem EPL e PPLB',
                        'Z301 - Linguagem ZPL',
                    ],
                },

                {
                    modelo: 'Modelo 302',
                    image: Eti302,
                    texts: [
                        'A302 - Linguagem PPLA | Argox',
                    ],
                },

                {
                    modelo: 'Modelo 303',
                    image: Eti303,
                    texts: [
                        'A303 - Linguagem PPLA | Argox',
                        'E303 - Linguagem EPL e PPLB',
                    ],
                },

                {
                    modelo: 'Modelo 304',
                    image: Eti304,
                    texts: [                        
                        'E304 - Linguagem EPL e PPLB',
                        'Z304 - Linguagem ZPL',
                    ],
                },

                {
                    modelo: 'Modelo 305',
                    image: Eti305,
                    texts: [                        
                        'E305 - Linguagem EPL e PPLB',
                        'Z305 - Linguagem ZPL',
                    ],
                },
            ],
        },

        {
            titulo: 'Acessórios - Tamanho 30x12mm - Alça na Direita',
            etiquetas: [
                {
                    modelo: 'Modelo 901',
                    image: Eti901,
                    texts: [
                        'E901 - Linguagem EPL e PPLB',
                        'Z901 - Linguagem ZPL',
                    ],
                },
                {
                    modelo: 'Modelo 902',
                    image: Eti902,
                    texts: [
                        'E902 - Linguagem EPL e PPLB',
                        'Z902 - Linguagem ZPL',
                    ],
                },
            ],
        },
    ];

    return (
        <>
        <div className="container">
            <div className="etiquetas-titulo-container">
                <h1 className="etiquetas-titulo">Etiquetas MASC</h1>
            </div>

            {sections.map((section, index) => (
                <div key={index}>
                    <h2 className="etiqueta-section-titulo">{section.titulo}</h2>
                    <div className="etiquetas-section">
                        {section.etiquetas.map((etiqueta, i) => (
                            <div className="etiqueta-card" key={i}>
                                <h3 className="etiqueta-modelo">{etiqueta.modelo}</h3>
                                <div className="etiqueta-conteudo">
                                    <img src={etiqueta.image} alt={etiqueta.modelo} className="etiqueta-imagem" />
                                    <div className="etiqueta-textos">
                                        {etiqueta.texts.map((text, j) => (
                                            <h4 key={j} className="etiqueta-texto">{text}</h4>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
        </>
    );
}

export default EtiquetasMasc;
